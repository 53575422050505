@font-face {
  font-family: 'PastureMap';
  src: url('~assets/fonts/pm/pasturemap-font.woff') format('woff'),
    url('~assets/fonts/pm/pasturemap-font.ttf') format('truetype'),
    url('~assets/fonts/pm/pasturemap-font.svg#pasturemap-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

.pm-font {
  display: inline-block;
  font-family: 'PastureMap' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pm-font.fa-stack-1x {
  line-height: inherit;
}

.pm-font-water-trough:before {
  content: '\61';
}
.pm-font-cattle-2:before {
  content: '\62';
}
.pm-font-gate:before {
  content: '\63';
}
.pm-font-bale:before {
  content: '\64';
}
.pm-font-rain-gauge:before {
  content: '\65';
}
.pm-font-other:before {
  content: '\66';
}
.pm-font-chicken-1:before {
  content: '\67';
}
.pm-font-pig-1:before {
  content: '\68';
}
.pm-font-sheep-1:before {
  content: '\69';
}
.pm-font-cattle-1:before {
  content: '\6a';
}
.pm-font-bison-1:before {
  content: 'F';
}
.pm-font-syringe:before {
  content: '\6b';
}
.pm-font-clipboard:before {
  content: '\6c';
}
.pm-font-soil-1:before {
  content: '\6d';
}
.pm-font-soil-2:before {
  content: '\6e';
}
.pm-font-fence-2:before {
  content: '\6f';
}
.pm-font-notebook-1:before {
  content: '\70';
}
.pm-font-notebook-2:before {
  content: '\71';
}
.pm-font-notebook-3:before {
  content: '\72';
}
.pm-font-notebook-4:before {
  content: '\73';
}
.pm-font-fence-1:before {
  content: '\74';
}
.pm-font-grass:before {
  content: '\75';
}
.pm-font-weigh-1:before {
  content: '\76';
}
.pm-font-note-1:before {
  content: '\77';
}
.pm-font-note-2:before {
  content: '\78';
}
.pm-font-shovel:before {
  content: '\6e';
}
.pm-font-donkey-1:before {
  content: '\e902';
}
.pm-font-horse-1:before {
  content: '\e903';
}
